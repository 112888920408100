import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from '../src/about-img.jpg';
import './App.css'; 
/**
 * AboutSection component renders the "About Us" section of the webpage.
 * It provides an overview of DataFitt's services and expertise in data analytics,
 * predictive modeling, and AI solutions. The section includes a descriptive text
 * and buttons for navigation to learn more about the company or get in touch.
 * Additionally, it displays an image to visually represent the company's mission.
 */
export const AboutSection = () => {
  return (
    <section className="container my-5" style={{backgroundColor:'#f9f9f9'}}>
      <div className="row align-items-center">
        <article className="col-md-6" style={{padding:'30px'}}>
          <h1 className="card-title mb-3" style={{color:'#00406c', fontWeight:'bold'}}>Maid Services in Islamabad</h1>
          <p className="card-text">
          At Al Falah Maid Services, we provide the best and most trusted maid services in Islamabad, Pakistan.
          Our team of experienced and trained maids is dedicated to making your home sparkle with our expertise.
          Imagine having a team of professionals taking care of your household chores, with the guarantee
          of quality service and customer satisfaction. We offer a wide range of services, from daily to live-in maids,
          so you can focus on what matters most to you.
          </p>
          <div className="button-container">
            <Link to="/AboutCompany">
              <button className="cus-button filled-btn" style={{width:'100%', borderRadius:'10px'}}>Discover More</button>
            </Link>
          </div>
        </article>
        <div className="col-md-6">
          <img
            src={image}
            alt="Team collaborating on data analytics"
            className="img-fluid rounded"
          />
        </div>
      </div>
    </section>
  );
}
